<template>
  <el-dialog
    title="用户信息"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="close"
  >
    <el-form ref="dataForm" label-width="80px" :rules="rules" :model="temp">
      <el-form-item label="所属单位" prop="uAdminID">
        <el-select
          v-model="temp.uAdminID_name"
          placeholder="请选择"
          @change="change_dw"
        >
          <el-option label="请选择" value="0"> </el-option>
          <el-option
            v-for="item in options_dw"
            :key="item.id"
            :label="item.aCpyName + item.aUserName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属部门" prop="uDeptID">
        <el-select
          v-model="temp.uDeptID_name"
          placeholder="请选择"
          @change="change_bm"
        >
          <el-option label="请选择" value="0"> </el-option>
          <el-option
            v-for="item in options_bm"
            :key="item.id"
            :label="item.dName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="昵称" prop="uNick">
        <el-input v-model="temp.uNick"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="uName">
        <el-input v-model="temp.uName"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="uTel">
        <el-input v-model="temp.uTel"></el-input>
      </el-form-item>
      <el-form-item label="所属区域" prop="uCityID">
        <el-select
          v-model="temp.uCityID_name"
          placeholder="请选择"
          @change="change_qy"
        >
          <el-option label="请选择" value="0"> </el-option>
          <el-option
            v-for="item in options_qy"
            :key="item.id"
            :label="item.cName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审核状态" prop="uChecked">
        <el-radio-group v-model="temp.uChecked" @change="change_checked">
          <el-radio-button label="0">禁用</el-radio-button>
          <el-radio-button label="1">启用</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row_type: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    row_type(val) {
      Object.assign(this.temp, val);
    },
    show(val, oVal) {
      if (val != oVal) {
        this.dialogVisible = val;
      }
      this.init();
    },
  },
  data() {
    return {
      bm_ay: [],
      options_bm: [],
      options_dw: [],
      options_qy: [],
      dialogVisible: false,
      temp: {
        id: "",
        uOpenid: "",
        uNick: "",
        uImg: "",
        uAdminID: 0,
        uAdminID_name: "",
        uDeptID: 0,
        uDeptID_name: "",
        uName: "",
        uTel: "",
        uCityID: 0,
        uCityID_name: "",
        uChecked: 0,
      },
      rules: {
        uNick: [
          {
            required: true,
            message: "昵称必填",
            trigger: "blur",
          },
        ],

        uName: [
          {
            required: true,
            message: "姓名必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    change_bm(val) {
      this.temp.uDeptID = val;
    },
    change_qy(val) {
      this.temp.uCityID = val;
    },
    change_dw(val) {
      this.temp.uAdminID = val;
      this.options_bm = this.bm_ay[val];
      this.temp.uDeptID_name = "";
    },
    init() {
      this.$api.get_user_infos({}).then((res) => {
        this.options_dw = res.data.list_dw;
        this.options_qy = res.data.list_citys;
        this.bm_ay = res.data.list_dept;
      });
    },
    change_checked(val) {
      this.temp.uChecked = val;
    },
    update() {
      this.$emit("update");
    },
    close() {
      this.$emit("close");
    },
    save() {
      // console.log(this.temp);
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          // this.temp.id = 0; // mock a id
          this.$api.users_save(this.temp).then((res) => {
            this.close();
            this.update();
            this.$notify({
              title: "温馨提示",
              message: res.data.msg,
              type: "success",
              duration: 2000,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
